$(document).ready(function(){

    $(".btn-estatus").click(function(event){
        let estatus = $(this).data('estatus');

        $("#estatus").val(estatus);

        if(estatus=='2' || estatus=='5')
        {
            $("#divObservaciones").show();
            $("#observaciones").prop('required', true);
        }
        else
        {
            $("#observaciones").val('');
            $("#divObservaciones").hide();
            $("#observaciones").prop('required', false);
        }
    });

    $(".btn-confirm").click(function(event){
        event.preventDefault();

        Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2fa360',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar'
          }).then((result) => {
            if (result.value) {
                $("#frmRespuesta").submit();
            }
          });  
    });

    $("#btn-regresar").click(function(event){
        event.preventDefault();

        let id     = $(this).data('id');
        let back   = $(this).prop('href');

        if(id){
            $.ajax({
                method: "POST",
                url: "update",
                headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
                data: { id }
            })
            .done(function( data ) {

            if(data.success==true)
            {
                    $(location).attr('href', back);
            }
            else
            {
                console.log(data);
            }
            });
        }

    });


    $("#btnUploadNSS").click(function(event){
        event.preventDefault();

        $("#fileNSS").click();
    });

    $("#fileNSS").change(function(event){

        let file = this.files[0];

        if (file.size > 3000000) {
            alert('El archivo no debe superar los 3MB');
            return false;
        }

        $("#btnUploadNSS").prop('disabled', true);

        $.ajax({
            method: "POST",
            url: 'upload',
            headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
            data: new FormData($('#frmUpload')[0]),
            cache: false,
            contentType: false,
            processData: false,
        
            // Custom XMLHttpRequest
            xhr: function () {

                $("progress").removeClass('d-none');
                var myXhr = $.ajaxSettings.xhr();

                if (myXhr.upload) {
                    // For handling the progress of the upload
                    myXhr.upload.addEventListener('progress', function (e) {
                    if (e.lengthComputable) {
                        $('progress').attr({
                        value: e.loaded,
                        max: e.total,
                        });
                    }
                    }, false);
                }
                return myXhr;
            }
          }) .done(function( data ) {

            $("#btnUploadNSS").prop('disabled', false);

            $("#checkFile").removeClass('fa-check fa-times');

            if(data.success==true)
            {
                $("#checkFile").addClass('fa-check text-success');
                $("#docNSS").removeClass('d-none');
                $("#docNSS").attr('href', data.file);
            }
            else
            {
                $("#checkFile").addClass('fa-times text-danger');
                console.log(data);
            }

            $("#checkFile").removeClass('d-none');
        });
    });
});